import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  /** common reference */
  userType: any[] = ['Monsieur', 'Madame', 'Mademoiselle  '];
  nationalities: any[] = ['Kolkata', 'Mumbai', 'Odisha'];
  profession: any[] = ['IT', 'Professor', 'Worker'];
  yesno: any[] = ['Oui', 'Non'];
  incidents: any[] = ['ANNULATION', 'RETARD', 'SURBOOKING'];
  incident_codes: any = { 'ANNULATION': '-1', 'RETARD': '-2', 'SURBOOKING': '-3' };
  parent_status: any = { 1: 'Acquisition/validation', 2: 'Précontentieux', 3: 'Contentieux', 4: 'Recouvrement' };
  step_status: any = {
    0: { name: 'Inactive', position: 0 },
    1: { name: 'à valider', group: 'MED1', position: 1 },
    2: { name: 'MED1 à générer', group: 'MED1', position: 2 }, // clicked on MED1
    3: { name: 'MED1 à générer', group: 'MED1', position: 3 }, // clicked on company generate csv
    4: { name: 'MED1 - En attente AR', group: 'MED1', position: 4 }, // clicked on company Ajouter à l'envoi
    5: { name: 'MED1 - En attente Rep. Cie', group: 'MED1', position: 5 }, // Date de réception MED1  added
    6: { name: 'En attente quittance', group: 'MED1', position: 6 }, // 'Type réponse compagne' field value is 'Favorable'
    // End med 1, if not got any response or  if not favourite go to stage 12 A générer (MED2)
    7: { name: 'Relance à générer', group: 'Relance', position: 7 }, // after 15 days no response from company
    8: { name: 'Relance à générer', group: 'Relance', position: 8 }, // clicked on generate csv
    9: { name: 'En attente AR (Relance)', group: 'Relance', position: 9 }, // clicked onAjouter à l'envoi
    10: { name: 'En attente Rep. Cie (Relance)', group: 'Relance', position: 10 }, // Date de réception Relance  added
    11: { name: 'En attente quittance', group: 'Relance', position: 111 }, // is 'Type réponse compagne' is 'Favorable'
    // End Relance , if not got any response or not favourite on med1 or not favourite on Relance

    12: { name: 'A générer (MED2)', group: 'MED2', position: 12 }, // 'Type réponse compagne' exists AND is not 'Favorable'
    13: { name: 'A générer (MED2)', group: 'MED2', position: 13 }, // clicked on company generate csv
    14: { name: 'En attente AR (MED2)', group: 'MED2', position: 14 }, // clicked on company Ajouter à l'envoi
    15: { name: 'En attente Rep. Cie (MED2)', group: 'MED2', position: 15 }, // Date de réception Med 2  added
    16: { name: 'En attente quittance', group: 'MED2', position: 16 }, // 'Type réponse compagne' field value is 'Favorable'
    // End of med 2
    17: { name: 'A paramétrer', group: 'MED2', position: 17 }, // 15 days after the date in the field 'Date de réception MED2' AND the field 'Type réponse compagne' is null
    18: { name: 'Requête à générer', group: 'MED2', position: 18 }, // Click on  "Requête"
    19: { name: 'Médiation à générer', group: 'MED2', position: 19 }, // Click on  "Médiation"
    20: { name: 'Assignation à générer', group: 'MED2', position: 20 }, // Click on  "Assignation"
    21: { name: 'Requête à générer', group: 'MED2', position: 21 }, // clicked on generate csv
    22: { name: 'Attente réception Requête', group: 'MED2', position: 22 }, // clicked on company Ajouter à l'envoi
    23: { name: 'Médiation à générer', group: 'MED2', position: 23 }, //  clicked on generate csv
    24: { name: 'Attente réception', group: 'MED2', position: 24 }, // clicked on company Ajouter à l'envoi
    25: { name: 'Assignation à générer', group: 'MED2', position: 25 }, //  clicked on generate csv
    26: { name: 'Assignation à générer', group: 'MED2', position: 26 }, // clicked on company Ajouter à l'envoi
    27: { name: 'En attente convocation', group: 'MED2', position: 22 }, // when the field "Date envoi requête" exists (from ""Requête"'s form)
    // 28: {name : 'En attente avis médiateur' , group : 'MED2', position: 28}, // generate receipt,,suivi des envois
    29: { name: 'En attente 1ère exp.', group: 'MED2', position: 29 }, // when the field "Date envoi requête" exists (from ""Requête"'s form)
    30: { name: 'En attente 1ère exp.', group: 'MED2', position: 30 }, // when the field "Date envoi requête" exists (from ""Requête"'s form)
    31: { name: 'En attente retour 1ère expédition', group: 'MED2', position: 31 }, // Assignation suivi devis
    //  32: {name : 'En attente quittance' , group : 'MED2', position: 32}, // mediation success
    33: { name: 'Montant', group: 'MED2', position: 33 }, // mediation success
    34: { name: 'En attente quittance audience', group: 'MED2', position: 34 }, // audience success
    //Mediation status
    // 28: {name : 'Médiation à générer' , group : 'MED2', position: 28}, // audience success
    //36: {name : 'Attente réception' , group : 'MED2', position: 28}, // audience success
    37: { name: 'Attente décision', group: 'MED2', position: 24 }, // audience success
    38: { name: 'Succès Médiation', group: 'MED2', position: 24 }, // audience success
    39: { name: 'Echec Médiation', group: 'MED2', position: 24 }, // audience success
    40: { name: 'En attente quittance', group: 'MED2', position: 35 }, // quittance
    41: { name: 'A Signer quittance', group: 'MED2', position: 36 }, // quittance
    42: { name: 'Signée quittance', group: 'MED2', position: 37 }, // quittance

    43: { name: 'Jugement', group: 'MED2', position: 38 }, // quittance
    45: { name: 'Paiement', group: 'MED2', position: 39 }, // quittance
    47: { name: 'Suvi Carpa', group: 'MED2', position: 40 }, // quittance
    99: { name: 'Clos', group: 'MED2', position: 120 }, // clos success
    100: { name: 'Clos - pax déjà indemnisé', group: 'MED2', position: 121 }, // clos success
    101: { name: 'Clos - CIREX', group: 'MED2', position: 122 }, // clos success
    102: { name: 'Clos - Annulé', group: 'MED2', position: 123 }, // clos success
    103: { name: 'Clos - Transaction', group: 'MED2', position: 124 }, // clos success
    110: { name: 'Standby', group: 'ANY', position: 131 },
  };

  step_substatus: any = {
    1: { name: 'Recouvrement à faire' },
    2: { name: 'StandBy' },
    3: { name: 'Clos' },
    4: { name: 'A Supprimer' }
  }
  group_status: any = { 1: 'MED1', 2: 'Relance', 3: 'MED2' };
  categories: any[] = ['Lettre de mission', 'Justificatif 1', 'Pièce d’identité', 'A typer'];
  menuItems: any[] = [
    'A typer',
    'Attestation de vol',
    'Lettre de mission',
    'Pièce d’identité',
    'Carte d’embarquement',
    'Titre de réservation',
    'Autre',
    'Relevé de vol',
    'Ticket Bagage',
    'Attestation retard/annulation',
    'Pouvoir de l’avocat',
    'Echanges client-compagnie',
    'Reponse de la compagnie',
    'Copie du livret de famille (si mineur)',
    'Facture du vol intial (art.8) - Facture des frais de réacheminement (art.8)',
    'Facture des frais défaut de prise en charge (art.9)',
    //'Courriel Aulnay-sous-bois',
    'Avis du médiateur',
    //'Réponse compagnie',
    //'Pièces jurisprudences recevabilité',
    //'Pièce Obligation d\'information',
    //'Extrait Kbis de la société',
    // 'Pièce défaut d\'assstance (Art8)',
    // 'Pièce défaut de prise en charge (Art9)',
    // 'Pièce Problème avec le personnel de bod',
    // 'Pièce Grève externe (autre)',
    'Pièce Grève externe (ATC français)',
    'Pièce Météo défavorable (général)',
    'Pièce Météo défavorable (vents violents)',
    'Pièce Météo défavorable (autres vols non affectés)',
    'Pièce Météo défavorable (visibilité)',
    'Pièce Météo défavorable (neige)',
    'RIB CARPA',
    'Quittance signée',
    'Quittance à signer',
    'Jugement',
  ];
  indemnites: any[] = ['Aucune', 'Défaut d’assistance', 'Défaut de prise en charge', 'Défaut d’assistance et de prise en charge'];
  assistance: any[] = [1, 2, 3, 4, 5];
  userRole: any[] = [{ id: 1, role: 'Manager' }, { id: 2, role: 'Teammate' }];
  userCompany: any[] = ['PLR', 'Indemniflight', 'POPULETIC', 'RECLAMIO', 'Flightright'];
  confrere: any[] = ["HFW", "PRADON/CLYDE&CO", "AIR FRANCE", "MINARD-DRISS", "REMBEAUVILLE", "FTPA", "CHEVRIER", "BOUAJILA", "DRIRA", "ZSCHUNKE", "ANCHETABEHERE", "POURRE", "FOURQUET", "ZUCARRELLI", "DONAT", "GOMEZ/DOLLA-VIAL", "SQUIRE PB", "KENNEDY'S", "DEMAY", "GUIRAL", "ANTUNES", "LLOP/EQUINOXE", "MOURA", "DREUX", "LASBEUR", "LIENARD", "LEXLINE", "QUENET"];
  juriste: any[] = ["Arnaud CORDIER", "Maxime CROS"];
  plaideur: any[] = ["Hélène MARINOPOULOS", "Gabrielle PONSIN", "Elisa DELAMADELEINE", "Antoine ANGLES d'AURIAC", "Sylvain ROUEN"];

  countries: any;
  airlines: any;
  airport: any;
  juri: any;
  mediator: any;
  huissier: any;
  rollBackHistory: any;
  kisData: any;
  kisImport: any;

  /**  user data */
  business: any;
  paxs: any[];
  vol: any[];
  audiences: any[];
  montant: any = {};
  paiement: any = {};
  quitance: any = {};
  judgement: any = {};
  suivi_carpa: any = {};
  experience: any = {};
  courier: any = {};
  requete: any = {};
  mediation: any = {};
  assignation: any = {};
  files: any[];
  incident_type_status: any = '';

  constructor() {
    /*this.audiences = [{
      audience_date: '',
      audience_heure: '',
      deplacement: '',
      procedure: '',
      juridiction: '',
      audience_nrg: '',
      echanges_compagnie: '',
      confrere: '',
      juriste: '',
      plaideur: '',
      action_avant_audience: '',
      etat: '',
      prevision: '',
      realite: '',
      retour_daudience: '',
      motif: '',
      commentaires: ''
    }];*/
  }

  getAirPortId(value: any) {
    const list: any[] = this.airport.filter((obj => {
      return (value === obj['aeroport_nom']);
    }));
    if (list && list.length > 0) {
      return list[0];
    }
  }
  getAirLinesData(value: any) {
    const list: any[] = this.airlines.filter((obj => {
      return (value === obj['nom']);
    }));
    if (list && list.length > 0) {
      return list[0];
    }
  }
  getMediatorData(value: any) {
    const list: any[] = this.mediator.filter((obj => {
      return (value === obj['NomMediateur']);
    }));
    if (list && list.length > 0) {
      return list[0];
    }
  }

  getIdToRole(value: any) {
    const list: any[] = this.userRole.filter((obj => {
      return (value === obj.id);
    }));
    if (list && list.length > 0) {
      return list[0];
    }
  }

  filterSpecial(value: any) {
    return value.toString().replace(/[^a-zA-Z0-9]/g, '');
  }

  escapeRegExp(string: any) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  fileExtension(file) {
    return file.split('.').pop().toLowerCase();
  }
  replaceDecimal(num, type) {
    if (num != undefined) {
      if (num.toString().indexOf('.') !== -1 && type === 0) {
        num = parseFloat(num).toFixed(2);
      }
      return type == 1 ? num.toString().replace(',', '.') : num.toString().replace('.', ',');
    } else {
      return '';
    }

  }

  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  }
}
