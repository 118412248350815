import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class NetworkHandlerService {

  baseUrl = environment.baseUrl;
  fileUrl = environment.fileUrl;
  kisGateUrl = environment.kisGateUrl

  constructor(private http: HttpClient) { }

  getHeader() {
    const headerParams = { 'Content-Type': 'application/json; charset=utf-8' };
    const user_params = this.user_params();
    if (user_params.app_key !== undefined) {
      headerParams['rkey'] = btoa(JSON.stringify({ userid: user_params.userid, app_key: user_params.app_key }));
    }
    /* if (user_params.userid !== undefined) {
         headerParams['app_key'] = user_params.app_key;
     }*/

    const headers = new HttpHeaders(headerParams);
    return { headers };
  }

  create(postBody: any) {
    const url = `${this.baseUrl}cbus`;
    return this.http.post(url, postBody, this.getHeader());
  }

  getPax(postBody: any) {
    const url = `${this.baseUrl}pax`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  getVol(postBody: any) {
    const url = `${this.baseUrl}vol`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  getFiles(postBody: any) {
    const url = `${this.baseUrl}files`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  savePax(postBody: any) {
    const url = `${this.baseUrl}save_pax`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  saveVol(postBody: any) {
    const url = `${this.baseUrl}save_vol`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  saveBusiness(postBody: any) {
    const url = `${this.baseUrl}save_bus`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  getCity(postBody) {
    const url = `${this.baseUrl}city`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  getCountryList() {
    return this.http.get('assets/data/country.json').map(res => res);
  }

  getNationalityList() {
    return this.http.get('assets/data/nationality.json').map(res => res);
  }


  getAirLinesList() {
    return this.http.get('assets/data/airlines1.json').map(res => res);
  }


  getAirportList() {
    return this.http.get('assets/data/airport1.json').map(res => res);
  }
  getJuriList() {
    return this.http.get('assets/data/juri.json').map(res => res);
  }

  getMediaList() {
    return this.http.get('assets/data/mediator.json').map(res => res);
  }

  filterRecherche(postBody: any) {
    const url = `${this.baseUrl}sbus`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  dossierData(postBody: any) {
    const url = `${this.baseUrl}sbushome`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }



  getUserList(postBody: any) {
    const url = `${this.baseUrl}user`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  getPriceList(postBody: string) {
    const url = `${this.baseUrl}price_list`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  uploadImage(file: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data;' });
    const formData = new FormData();
    formData.append('userFile', file);
    const url = `${this.baseUrl}fileupload`;
    return this.http.post(url, formData).map(res => res);
  }

  fileMetadataPost(postBody: any) {
    const url = `${this.baseUrl}save_document`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  updatefileMetadataPost(postBody: any) {
    const url = `${this.baseUrl}update_document`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  updateAirDistance(postBody: string) {
    const url = `${this.baseUrl}save_air_distance`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  updatePrice(postBody: any) {
    const url = `${this.baseUrl}save_price`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  getDistance(postBody: string) {
    const url = `${this.baseUrl}air_distance`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  getPrice(postBody: string) {
    const url = `${this.baseUrl}get_price`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  addUser(postBody: any) {
    const url = `${this.baseUrl}save_user`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  updateUser(postBody: any) {
    const url = `${this.baseUrl}update_user`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  updateProfile(postBody: any) {
    const url = `${this.baseUrl}update_profile`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  updatePassword(postBody: any) {
    const url = `${this.baseUrl}update_password`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  deleteUser(postBody: any) {
    const url = `${this.baseUrl}delete_user`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  genericPost(postBody: string, query: string) {
    const url = this.baseUrl + query;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }
  genericService(postBody: any, query: string) {
    const url = this.baseUrl + query;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }
  logout(postBody: string) {
    const url = `${this.baseUrl}logout`;
    return this.http.post(url, postBody, this.getHeader()).map(res => res);
  }

  user_params() {
    return JSON.parse(atob(localStorage.getItem('user')));
  }

  getKBISDocuments(postBody: any) {
    const url = `${this.baseUrl}getkbis`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  saveKBISDocuments(postBody: any) {
    const url = `${this.baseUrl}save_kbis_document`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  deleteKBISDocuments(postBody: any) {
    const url = `${this.baseUrl}delete_kbis_document`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  updateKBISDocuments(postBody: any) {
    const url = `${this.baseUrl}update_kbis_document`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  //yassir network handler
  cloturerDossier(postBody: any) {
    const url = `${this.baseUrl}cloture_dossier`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  rollBackMed1(postBody: any) {
    const url = `${this.baseUrl}rollback_med1`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  rollBackRelance(postBody: any) {
    const url = `${this.baseUrl}rollback_relance`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  rollBackMed2(postBody: any) {
    const url = `${this.baseUrl}rollback_med2`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  rollBackHistory(postBody: any) {
    const url = `${this.baseUrl}rollback_history`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  kisData(postBody: any) {
    const url = `${this.baseUrl}kis_data`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  kisImport() {
    const url = `${this.kisGateUrl}triggerKIS`;
    return this.http.get(url).map(res => res);
  }

  etapeSuivanteMed1(postBody: any) {
    const url = `${this.baseUrl}etape_suivante_med1`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  etapeSuivanteRelance(postBody: any) {
    const url = `${this.baseUrl}etape_suivante_relance`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  etapeSuivanteMed2(postBody: any) {
    const url = `${this.baseUrl}etape_suivante_med2`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  go_standby(postBody: any) {
    const url = `${this.baseUrl}put_on_standby`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

  back_from_standby(postBody: any) {
    const url = `${this.baseUrl}remove_standby`;
    return this.http.post(url, JSON.stringify(postBody), this.getHeader()).map(res => res);
  }

}
