import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { StoreService } from '../../../dashboard/store.service';
import { UserAuth } from '../../../dashboard/user.auth';
import { Router } from '@angular/router';
import { NzModalService, NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { NetworkHandlerService } from '../../../dashboard/network-handler.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {

    searchVisible: any = false;
    quickViewVisible: any = false;
    isFolded: boolean;
    isExpand: boolean;
    confirmModal: NzModalRef;

    notificationList: any = [
        {
            title: 'You received a new message',
            time: '8 min',
            icon: 'mail',
            color: 'ant-avatar-' + 'blue'
        },
        {
            title: 'New user registered',
            time: '7 hours',
            icon: 'user-add',
            color: 'ant-avatar-' + 'cyan'
        },
        {
            title: 'System Alert',
            time: '8 hours',
            icon: 'warning',
            color: 'ant-avatar-' + 'red'
        },
        {
            title: 'You have a new update',
            time: '2 days',
            icon: 'sync',
            color: 'ant-avatar-' + 'gold'
        }
    ];
    userObj: any = '';
    isUserEditVisible: any = false;
    isPasswordEditVisible: any = false;
    editProfile: FormGroup;
    editPassword: FormGroup;
    isSpinning: any = false;

    constructor(
        private router: Router,
        private themeService: ThemeConstantService,
        public store: StoreService,
        public auth: UserAuth,
        private modalService: NzModalService,
        private message: NzMessageService,
        private networkHandler: NetworkHandlerService,
        private fb: FormBuilder
    ) {
        this.userObj = JSON.parse(atob(localStorage.getItem('user')));
        this.userObj['role'] = this.store.getIdToRole(this.userObj.role_id).role;
    }

    ngOnInit(): void {
        this.editProfile = this.fb.group({
            name: [null, [Validators.required]],
            username: [null, [Validators.required]]
        });
        this.editPassword = this.fb.group({
            password: [null, [Validators.required]]
        });

        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    quickViewToggle(): void {
        this.quickViewVisible = !this.quickViewVisible;
    }

    onLogout(): void {
        this.confirmModal = this.modalService.confirm({
            nzTitle: 'Do you want to logout?',
            nzContent: '',
            nzOkText: 'Yes',
            nzOkType: 'danger',
            nzOnOk: () => new Promise((resolve, reject) => {
                this.logout();
                setTimeout(Math.random() > 0.5 ? resolve : reject, 200);
            }).catch(() => console.log('Oops errors!'))
        });
    }

    async logout() {
        const obj: any = {};
        obj.userid = this.userObj.userid;
        obj.app_key = this.userObj.app_key;
        const response: any = await this.networkHandler.logout(JSON.stringify(obj)).toPromise();
        localStorage.removeItem('user');
        this.router.navigateByUrl('/authentication/login');
    }

    handleEditCancel() {
        this.isUserEditVisible = false;
    }

    async handleEditOk() {
        // console.log(this.userEditForm);
        for (const i in this.editProfile.controls) {
            if (this.editProfile.controls[i]) {
                this.editProfile.controls[i].markAsDirty();
                this.editProfile.controls[i].updateValueAndValidity();
            }
        }
        if (this.editProfile.valid) {
            try {
                this.isSpinning = true;
                const obj: any = JSON.parse(JSON.stringify(this.editProfile.value));
                //obj.role_id = this.userObj.role_id;
                //obj.role = this.userObj.role;
                const response: any = await this.networkHandler.updateProfile(obj).toPromise();
                if (response) {
                    console.log(`user -> ${response}`);
                    if (response.status) {
                        this.userObj.username = response.username;
                        this.userObj.name = response.name;
                       // console.log(btoa(JSON.stringify(this.userObj)));
                        localStorage.setItem('user', btoa(JSON.stringify(this.userObj)));
                        this.isSpinning = false;
                        this.isUserEditVisible = false;
                        this.message.success(`${this.editProfile.value.name} updated successfully`, { nzDuration: 2000 });
                    } else {
                        this.message.error(`${response.error}`, { nzDuration: 2000 });
                    }
                }
                this.isSpinning = false;
            } catch (error) {
                this.auth.authRedirect(error);
                this.isSpinning = false;
                this.message.error(`${error.message}`, { nzDuration: 2000 });
            }
        }
    }


    handlePwdCancel() {
        this.isPasswordEditVisible = false;
    }

    async handlePwdOk() {
        // console.log(this.userEditForm);
        for (const i in this.editPassword.controls) {
            if (this.editPassword.controls[i]) {
                this.editPassword.controls[i].markAsDirty();
                this.editPassword.controls[i].updateValueAndValidity();
            }
        }
        if (this.editPassword.valid) {
            try {
                this.isSpinning = true;
                const obj: any = JSON.parse(JSON.stringify(this.editPassword.value));

                const response: any = await this.networkHandler.updatePassword(obj).toPromise();
                if (response) {
                    console.log(`user -> ${response}`);
                    if (response.status) {
                        this.isSpinning = false;
                        this.isPasswordEditVisible = false;
                        this.message.success(`Password updated successfully`, { nzDuration: 2000 });
                    } else {
                        this.message.error(`${response.error}`, { nzDuration: 2000 });
                    }
                }
                this.isSpinning = false;
            } catch (error) {
                this.auth.authRedirect(error);
                this.isSpinning = false;
                this.message.error(`${error.message}`, { nzDuration: 2000 });
            }
        }
    }

}
