import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: '/dashboard/default',
        title: 'Accueil',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'home',
        submenu: []
    },
    {
        path: '/dashboard/rechercher',
        title: 'Rechercher',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'search',
        submenu: []
    },
    {
        path: '/dashboard/creer',
        title: 'Créer affaire',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'team',
        submenu: []
    },
    {
        path: '/dashboard/generer',
        title: 'Générer courrier',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'mail',
        submenu: []
    },
    {
        path: '/dashboard/kanban',
        title: 'Recouvrement',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'euro',
        submenu: []
    },
    {
        path: '/dashboard/suivides',
        title: 'Suivi des envois',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'compass',
        submenu: []
    }
];
