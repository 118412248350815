import { Component, OnInit } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { SideNavInterface } from '../../interfaces/side-nav.type';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent implements OnInit {

    public menuItems: any[] = ['Accueil', 'Rechercher', 'Créer affaire'];
    isFolded: boolean;
    isSideNavDark: boolean;

    constructor(private themeService: ThemeConstantService) { }

    ngOnInit(): void {
        this.menuItems = ROUTES.filter((menuItem: SideNavInterface) => {
            return (this.menuItems.includes(menuItem.title));
        });
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    toggleFold(event) {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
        if (!event.target.classList.contains('highlighted')) {
            event.target.classList.add('highlighted');
        } else {
            event.target.classList.remove('highlighted');
        }
    }

    reConfigSideNav() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
}
