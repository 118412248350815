import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserAuth {

    constructor(private router: Router) { }

    authRedirect(error: any) {
      console.log(error);
        if (error.status === 401) {
            this.router.navigateByUrl('/authentication/login');
        }
    }


}
